/**
 * @file Sectors.jsx
 * @author Duncan Grubbs <duncan.grubbs@gmail.com>
 * @description Sectors information and filtering page.
 */

import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import * as Layout from 'react-bootstrap';

import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import CircularProgress from '@material-ui/core/CircularProgress';
import Paper from '@material-ui/core/Paper';
import Api from '../../middleware/Api';
import sectorIocn from '../../images/sectors_icon1.png';

const useStyles = makeStyles((theme) => ({
	sector: {
		padding: theme.spacing(2),
		cursor: 'pointer',
	},
	title: {
		margin: theme.spacing(3, 0),
	},

}));

function Sectors() {
	const [sectors, setSectors] = useState({});
	const [loading, setLoading] = useState(true);

	const classes = useStyles();
	const history = useHistory();

	function fetchData() {
		Api
			.GET('/sectors')
			.then((sectors) => {
				setSectors(sectors);
				setLoading(false);
			});
	}

	function handleClick(event) {
		console.log(event.currentTarget);
		history.push(`/sector/${event.currentTarget.id}`);
	}

	useEffect(() => {
		fetchData();
	}, []);

	return (
		<React.Fragment>
			<div className="sectors-box mx-auto">
				<Layout.Container className='mt-5'>
					<div className="d-flex align-items-center justify-content-center mb-4">
						{loading && <CircularProgress className='text-white me-2' />}
						<h1 className="title text-uppercase text-center mt-3" style={{ color: '#efad1e', fontSize: 28, fontWeight: 700 }}>Browse Sectors</h1>
					</div>
					<Layout.Row >
						{Object.keys(sectors).map((sector) => {
							if (sectors[sector].name !== 'TBD') {
								return (
									<Layout.Col key={sector} xs={6} sm={6} md={3} className="mt-5 mb-5">
										<div id={sector} className="text-center text-white cursor-pointer" onClick={handleClick}>
											<img src={'assets/sectors/' + sectors[sector].name + '.svg'} alt="icon" className="sectorimg" />
											<span className='d-block mt-2 fs-5' style={{ fontWeight: 300 }}>{sectors[sector].name}</span>
										</div>
									</Layout.Col>
								);
							}
						})}
					</Layout.Row>
				</Layout.Container>
			</div>
		</React.Fragment>
	);
}

export default Sectors;
