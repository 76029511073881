/**
 * @file Home.jsx
 * @author Duncan Grubbs <duncan.grubbs@gmail.com>
 * @description Dashboard of the frontend, core app functionlity lives
 * here
 * file is edited by Shehayar abbasi <sheharyar.abbasi@gmail.com>
 */
import './Home.css';
import React, { useEffect, useState } from 'react';
import { withRouter, useHistory } from 'react-router-dom';
import Highlighter from 'react-highlight-words';
import { FixedSizeList } from 'react-window';
import * as Layout from 'react-bootstrap';
import { makeStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import LinearProgress from '@material-ui/core/LinearProgress';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { withStyles } from '@material-ui/core/styles';
import Checkbox from '@material-ui/core/Checkbox';
import Select, { createFilter, components } from 'react-select';
import Api from '../../middleware/Api';
import Chart from '../../components/Chart/Chart';
import log from '../../util/logger';
import arrow_icon from '../../images/arrow_icon.png';
import comIcon2 from '../../images/company_icon2.png';
import statsIcon from '../../images/stats_icon.png';
import callIcon from '../../images/call_icon.png';
import SectorSearchIcon from '../../icons/SectorSearchIcon';
import StockListings from '../../icons/StockListings';
import CompanyStatisticsIcon from '../../icons/CompanyStatisticsIcon';
import SelectedSentancesIcon from '../../icons/SelectedSentancesIcon';

function MenuList(props) {
	const { options, children, maxHeight, getValue } = props;
	const [value] = getValue();
	const height = 35;
	const initialOffset = options.indexOf(value) * height;

	return (
		<FixedSizeList
			height={maxHeight}
			itemCount={children.length}
			itemSize={height}
			initialScrollOffset={initialOffset}
		>
			{({ index, style }) => <div style={style}>{children[index]}</div>}
		</FixedSizeList>
	);
}

const CustomCheckbox = withStyles({
	root: {
		color: "#ffffff",
		"&$checked": {
			color: "#006f3d"
		}
	},
	checked: {}
})((props) => <Checkbox color='default' {...props} />);

const useStyles = makeStyles((theme) => ({
	gridItem: {
		padding: theme.spacing(1, 10),
	},
	statsSection: {
		padding: theme.spacing(1, 3),
	},
	scrollingDocument: {
		maxHeight: '700px',
		overflowY: 'scroll',
	},
	querySection: {
		padding: theme.spacing(1, 2),
		overflowY: 'visible',
		maxHeight: '500px',
	},
	documentFulltext: {
		lineHeight: '1.5',
		fontSize: '1em',
		overflowX: 'wrap',
	},
	inputs: {
		display: 'flex',
		justifyContent: 'space-between',
		alignItems: 'center',
		width: '100%',
	},
	inputItem: {
		flex: 1,
		margin: theme.spacing(0, 2),
	},
	container: {
		marginTop: theme.spacing(2),
		maxWidth: '1600px',
	},
	item: {
		margin: theme.spacing(1, 0),
	},
}));

const customStyles = {
	control: (provided, state) => ({
		...provided,
		backgroundColor: '#000000',
		boxShadow: 'none',
		borderColor: "#fff",
		"&:hover": {
			borderColor: "#fff",
		}
	}),
	singleValue: (provided, state) => ({
		...provided,
		color: '#ffffff',
	}),
	indicatorsContainer: (provided, state) => ({
		...provided,
		padding: 2,
	}),
	indicatorContainer: () => ({
		backgroundColor: '#f00',
	}),
	indicatorSeparator: () => ({
		width: 0,
	}),
	dropdownIndicator: (provided, state) => ({
		transition: 'color 150ms',
		boxSizing: 'border-box',
		display: 'flex',
		padding: 8,
		color: '#ffffff',
		textIndent: '-9999px',
		width: 35,
		backgroundColor: '#0065a4',
		backgroundPosition: '0 0',
		backgroundRepeat: 'no-repeat',
		backgroundSize: 'cover',
		backgroundImage: `url(${arrow_icon})`,
	}),
	multiValueLabel: (provided, state) => ({
		...provided,
		color: '#ffffff',
		backgroundColor: '#efad1d',
		borderRadius: 0,
	}),
	multiValueRemove: (provided, state) => ({
		...provided,
		color: '#ffffff',
		backgroundColor: '#efad1d',
		borderRadius: 0,
		"&:hover": {
			backgroundColor: "#efad1d",
			color: "#fff"
		}
	}),
	option: (provided, state) => ({
		...provided,
		backgroundColor: '#ffffff',
		color: '#000000',
	})
};
const DropdownIndicator = (props) => {
	return (<components.DropdownIndicator {...props}>.</components.DropdownIndicator>);
}

function Home({ match }) {
	const {
		params: { tickers, selectedSector },
	} = match;
	const classes = useStyles();
	const [data, setData] = useState({});
	const [documentLoading, setDocumentLoading] = useState(false);
	const [chartLoading, setChartLoading] = useState(false);
	const [sectorOptions, setSectorOptions] = useState([]);
	const [tickerOptions, setTickerOptions] = useState([]);
	const [baseTickerOptions, setBaseTickerOptions] = useState([]);
	const [dictionary, setDictionary] = useState([]);
	const [sectorMap, setSectorMap] = useState({});
	const [stats, setStats] = useState();
	const [documentTitle, setDocumentTitle] = useState('');
	const [xSelection, setXSelection] = useState('date');
	const [chartTitle, setChartTitle] = useState('');
	const [selectedTickers, setTickers] = useState(
		tickers ? tickers.split(',') : []
	);
	const [paragraphs, setParagraphs] = useState([]);
	const [resetSector, setResetSector] = useState('');
	const [isSector, setIsSector] = useState('');
	const [sector, setSector] = useState(resetSector !== '' || selectedSector || 'None');
	const [filteredWords, setFilteredWords] = useState([]);
	const [selectedScore, setSelectedScore] = useState(0);
	const [checkedState, setCheckedState] = useState({
		indexscore: true,
		sd: false,
	});
	const [change, setChange] = useState(null);
	const [priorScore, setPriorScore] = useState(0);
	const [idCheck, setIdCheck] = useState('');
	const history = useHistory();

	const toggleXSelection = () => {
		if (xSelection === 'date') {
			setXSelection('quarterDate');
		} else {
			setXSelection('date');
		}
	};
	let selectRef = null;
	let selectRefSector = 'None';
	// reset page values function
	async function resetPageValues() {
		setIsSector('None');
		selectRefSector.select.clearValue();
		await selectRef.select.clearValue();
		await document.getElementById('submitHandler').click();
		setIsSector('');
	}

	const chartCallback = (requiredData, prior) => {
		setSelectedScore(requiredData);
		setPriorScore(prior);
	};

	const handleCheckChange = (event) => {
		const newState = {};
		Object.keys(checkedState).forEach((key) => {
			newState[key] = key === event.target.name;
		});
		setCheckedState(newState);
	};

	function firstDocCallBack(payload) {

	}

	function fetchDataPoints() {
		setChartLoading(true);

		let queryString = `/companies?tickers=${selectedTickers.join(',')}`;
		if (sector !== '' && sector !== 'None') {
			queryString = `/companies?tickers=${selectedTickers.join(
				','
			)}&sector=${sector}`;
		}

		Api.GET(queryString)
			.then((payload) => {
				setData(payload);
				setChartTitle(selectedTickers.join(', '));
				setChartLoading(false);
				let test = Object.entries(payload);
				test[0][1][0].id !== idCheck && (match.params.tickers === change.toString() && loadFulltext(test[0][1][0].id));
				setIdCheck(test[0][1][0].id);
			})
			.catch((error) => {
				setChartLoading(false);
				log(error);
			});
	}

	function loadFulltext(id) {
		setDocumentLoading(true);
		console.log(id);
		Api.GET(`/companies/document?id=${id}`)
			.then((payload) => {
				setStats(payload.stats);
				// setFilteredWords(payload.filteredWords);
				// setDictionary(payload.dictionary);
				// const fullTextWrapper = document.getElementById("document-fulltext");
				//
				// payload.filteredWords.map((element) => {
				//     if (payload.transcript.includes(element) === true) {
				//         payload.transcript = payload.transcript.replaceAll(
				//             element,
				//             "<mark>" + element + "</mark>"
				//         );
				//     }
				// });
				// fullTextWrapper.innerHTML = payload.transcript;
				setDocumentTitle(id.replace('.txt', ''));

				Api.GET(`/companies/documents?id=${id}`).then(response => {
					console.log(response);
					setParagraphs(response.data);
					// const fullTextWrapper = document.getElementById("document-fulltext");
					//
					// payload.filteredWords.map((element) => {
					//     if (payload.data.includes(element) === true) {
					//         payload.data = payload.data.replaceAll(
					//             element,
					//             "<mark>" + element + "</mark>"
					//         );
					//     }
					// });
					// fullTextWrapper.innerHTML = payload.data;
					setDocumentLoading(false);
					setFilteredWords(response.filteredWords);
					setDictionary(response.transcript);

				}).catch(error =>
					log(error));
				setDocumentLoading(false);
			})
			.catch((error) => {
				setDocumentLoading(false);
				log(error);
			});
	}

	function handleSubmit(event) {
		event.preventDefault();
		if (sector !== 'None' && sector !== '') {
			history.push(`/query/${selectedTickers.join(',')}/${sector}`);
		} else {
			history.push(`/query/${selectedTickers.join(',')}`);
		}
	}

	function filterTickersBySector(sector, sectorMap, tickersToFilter) {
		if (sector === 'None') return tickersToFilter;

		const validTickers = sectorMap[sector].tickers.map((ticker) => ticker[0]);

		const filteredTickers = tickersToFilter.filter((ticker) =>
			validTickers.includes(ticker.value)
		);

		return filteredTickers;
	}

	async function fetchDropdownData() {
		const sectors = await Api.GET('/sectors').catch((error) => {
			log(error);
		});

		let sectorList = Object.keys(sectors)
			.sort()
			.map((sector) => ({
				label: `${sector} - ${sectors[sector].name}`,
				value: sector,
			}));

		sectorList = [{ label: 'None', value: 'None' }, ...sectorList];

		setSectorMap(sectors);
		setSectorOptions(sectorList);

		const tickers = await Api.GET('/companies/tickers').catch((error) => {
			log(error);
		});

		const tickerDropdownOptions = Object.keys(tickers)
			.sort()
			.map((ticker) => ({
				label: `${ticker} - ${tickers[ticker][2]}`,
				value: ticker,
			}));

		if (sector !== 'None' && sector !== '') {
			const filteredTickers = filterTickersBySector(
				sector,
				sectors,
				tickerDropdownOptions
			);
			setTickerOptions(filteredTickers);
		} else {
			setTickerOptions(tickerDropdownOptions);
		}

		setBaseTickerOptions(tickerDropdownOptions);
	}

	useEffect(() => {
		change !== null && history.push(`/query/${change}`);
	}, [change]);


	function handleSectorChange(data) {
		if (data === null) {
			setCheckedState({ ...checkedState, sd: false });
			setTickerOptions(baseTickerOptions);
		} else {
			const { value } = data;
			setSector(value);

			if (value !== 'None') {
				const filteredTickers = filterTickersBySector(
					value,
					sectorMap,
					baseTickerOptions
				);

				setTickers([]);
				setTickerOptions(filteredTickers);
			}

			if (value === 'None') {
				setCheckedState({ ...checkedState, sd: false });
				setTickerOptions(baseTickerOptions);
			}
		}
	}
	function handleTickerChange(data) {
		data.length !== 0 && setChange(data.map((ticker) => ticker.value));
		setTickers(data.map((ticker) => ticker.value));
	}

	useEffect(() => {
		fetchDataPoints();
	}, [tickers]);

	useEffect(() => {
		fetchDropdownData();
	}, []);

	return (
		<React.Fragment>
			<div className="search-form bg-black px-3 pb-1 mb-3 bordertop borderbottom">
				<form
					noValidate
					onSubmit={handleSubmit}
					autoComplete="off"
					className='form-inline row'
				>
					<Layout.Col className='inline py-md-3 py-sm-1' sm={12} md={4}>
						<label className="label-col label-inline me-2">
							<SectorSearchIcon size={30} iconClass='me-2' />
							Sectors
						</label>
						<Select
							ref={ref => {
								selectRefSector = ref;
							}}
							components={{ DropdownIndicator }}
							id="sector"
							onChange={handleSectorChange}
							options={sectorOptions}
							value={isSector !== 'None' ? { label: sector, value: sector } : {
								label: 'None',
								value: 'None'
							}}
							styles={customStyles}
							className="form-control p-0 dropdown-green"
						/>
					</Layout.Col>
					<Layout.Col className='inline py-md-3 py-sm-1' sm={12} md={6}>
						<label className="label-col label-inline me-2">
							<StockListings size={30} iconClass='me-2' />
							<span style={{ width: 135 }}>Stock listing(s)</span>
						</label>
						<Select
							ref={ref => {
								selectRef = ref;
							}}
							id="tickers"
							onChange={handleTickerChange}
							filterOption={createFilter({ ignoreAccents: false })}
							value={selectedTickers.map((ticker) => ({
								label: ticker,
								value: ticker,
							}))}
							options={tickerOptions}
							components={{ DropdownIndicator, MenuList }}
							styles={customStyles}
							className='form-control p-0 dropdown-green'
							isMulti
						/>
					</Layout.Col>
					<Layout.Col className='inline btn-mobile py-md-3 py-sm-1' sm={12} md={2}>
						<Layout.Button
							id={'submitHandler'}
							variant="primary"
							type="submit"
							className='me-3 btn-blue'
						>
							Search
						</Layout.Button>
						<Layout.Button
							disabled={documentLoading}
							variant="success"
							type="submit"
							className='me-3 btn-green'
							onClick={() => resetPageValues()}
						>
							Reset
						</Layout.Button>
					</Layout.Col>
				</form>
				<Layout.Row>
					<Layout.Col>
						<div className="query-checkbox">
							<FormControlLabel
								control={
									<CustomCheckbox
										checked={checkedState.indexscore}
										onChange={handleCheckChange}
										name="indexscore"
										color='primary'
									/>
								}
								label="Regular Score"
							/>
							<FormControlLabel
								control={
									<CustomCheckbox
										checked={xSelection === 'quarterDate'}
										onChange={toggleXSelection}
										name="quarterDate"
										color='primary'
									/>
								}
								label="Group by Quarters"
							/>
							{sector !== 'None' && (
								<FormControlLabel
									control={
										<CustomCheckbox
											checked={checkedState.sd}
											onChange={handleCheckChange}
											name="sd"
											color='primary'
										/>
									}
									label="Standard Deviation"
								/>
							)}
						</div>
					</Layout.Col>
				</Layout.Row>
			</div>
			<Layout.Container>
				<Layout.Row>
					<Layout.Col sm={12} md={8} className='mb-2'>
						{chartLoading && <LinearProgress />}
						<Chart
							chartCallback={chartCallback}
							scoreSelection={checkedState}
							xSelection={xSelection}
							data={data}
							ticker={chartTitle}
							handleClick={loadFulltext}
						/>
					</Layout.Col>
					<Layout.Col sm={12} md={4} className='mb-2'>
						<Layout.Tabs
							defaultActiveKey="company-statistics"
							transition={false}
							id="statistics-tab"
							className="statistics"
						>
							<Layout.Tab
								eventKey='company-statistics'
								title={<CompanyStatisticsIcon size={50} />}
								tabClassName="tab-icons company"
							>
								<div className='company-statistics bg-white'>
									<p className='bg-statistics p-2 text-white fs-4'>Company Statistics</p>
									{stats && (
										<>
											<div className="d-flex align-items-center p-3">
												<img src={callIcon} alt="call icon" className="call-icon me-3" />
												<div className="d-block w-100">
													<p>
														<b>First Call</b>
														<br />
														<span>{new Date(stats.firstCall).toISOString().split('T')[0]}</span>
													</p>
													<p>
														<b>Last Call</b>
														<br />
														<span>{new Date(stats.lastCall).toISOString().split('T')[0]}</span>
													</p>
													<p className='mb-0'>
														<b>Number of Calls</b>
														<br />
														<span>{stats.numberOfCalls}</span>
													</p>
												</div>
											</div>
											<hr />
											<div className="d-flex align-items-center p-3">
												<img src={statsIcon} alt="stats icon" className="stats-icon me-3" />
												<div className="d-block w-100">
													<p>
														<b>Most Recent Score</b>
														<br />
														<span>
															{Math.round(stats.mostRecentScore * 100)}
														</span>
													</p>
													<p>
														<b>Selected Score</b>
														<br />
														<span>
															{selectedScore}
														</span>
													</p>
													<p>
														<b>Prior Score</b>
														<br />
														<span>
															{priorScore}
														</span>
													</p>
													<p>
														<b>Rolling 1 Year Average</b>
														<br />
														<span>
															{Math.round(stats.rollingYearAvg * 100)}
														</span>
													</p>
													<p>
														<b>Sector Year Average</b>
														<br />
														<span>
															{Math.round(stats.sectorYearAvg * 100)}
														</span>
													</p>
												</div>
											</div>
										</>
									)}
								</div>
							</Layout.Tab>
							<Layout.Tab
								eventKey='document-statistics'
								title={<img src={comIcon2} alt="company icon" />}
								tabClassName="tab-icons document"
							>
								<div className='document bg-white'>
									<p className='bg-document p-2 text-white fs-4'>Document</p>
									<div className="p-2 document-box">
										<h4>{documentTitle}</h4>
										{paragraphs.length !== 0 && paragraphs.map(para => (
											<p>
												<Highlighter
													highlightClassName="YourHighlightClass"
													searchWords={filteredWords}
													autoEscape={true}
													textToHighlight={para}
												/>
											</p>))
										}
									</div>
								</div>
							</Layout.Tab>
							<Layout.Tab
								eventKey='selected-sentences'
								title={<SelectedSentancesIcon size={50} />}
								tabClassName="tab-icons sentences"
							>
								<div className='bg-white'>
									<p className='bg-sentences p-2 text-white fs-4'>Selected Sentences</p>
									<div className="p-2 sentences-box">
										{dictionary.map((item, index) => (
											<p key={index.toString()}>
												<Highlighter
													highlightClassName="YourHighlightClass"
													searchWords={filteredWords}
													autoEscape={true}
													textToHighlight={item}
												/>
											</p>))
										}
									</div>
								</div>
							</Layout.Tab>
						</Layout.Tabs>
					</Layout.Col>
				</Layout.Row>
			</Layout.Container>
		</React.Fragment>
	);
}

export default withRouter(Home);
