/**
 * @file Sector.jsx
 * @author Duncan Grubbs <duncan.grubbs@gmail.com>
 * @description Sector information.
 */

import React, { useEffect, useState } from 'react';
import * as Layout from 'react-bootstrap';
import { FixedSizeList } from 'react-window';
import { withRouter, useHistory } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import Select, { createFilter, components } from 'react-select';
import Container from '@material-ui/core/Container';
import CircularProgress from '@material-ui/core/CircularProgress';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import Chip from '@material-ui/core/Chip';
import Api from '../../middleware/Api';
import log from '../../util/logger';
import './styles.css';
import arrow_icon from '../../images/arrow_icon.png';
import RealState from '../../icons/RealState';
import StockListings from '../../icons/StockListings';

const useStyles = makeStyles((theme) => ({
	sector: {
		padding: theme.spacing(2),
		cursor: 'pointer',
	},
	item: {
		padding: theme.spacing(1, 2),
		cursor: 'pointer',
	},
	itemSelected: {
		padding: theme.spacing(1, 2),
		cursor: 'pointer',
		color: '#fff',
		background: theme.palette.secondary.main,
	},
	title: {
		margin: '32px 0',
	},
	selectedLabel: {
		margin: '8px 0',
	},
	searchButton: {
		margin: '16px 0',
	},
	tickerList: {
		maxHeight: '600px',
		overflowY: 'scroll',
		margin: theme.spacing(2, 0),
	},
}));

const customStyles = {
	control: (provided, state) => ({
		...provided,
		backgroundColor: '#000000',
		boxShadow: 'none',
		borderColor: "#fff",
		"&:hover": {
			borderColor: "#fff",
		}
	}),
	singleValue: (provided, state) => ({
		...provided,
		color: '#ffffff',
	}),
	indicatorsContainer: (provided, state) => ({
		...provided,
		padding: 2,
	}),
	indicatorSeparator: () => ({
		width: 0,
	}),
	dropdownIndicator: (provided, state) => ({
		transition: 'color 150ms',
		boxSizing: 'border-box',
		display: 'flex',
		padding: 8,
		color: '#ffffff',
		textIndent: '-9999px',
		width: 35,
		backgroundColor: '#0065a4',
		backgroundPosition: '0 0',
		backgroundRepeat: 'no-repeat',
		backgroundSize: 'cover',
		backgroundImage: `url(${arrow_icon})`,
	}),
	multiValueLabel: (provided, state) => ({
		...provided,
		color: '#ffffff',
		backgroundColor: '#efad1d',
		borderRadius: 0,
	}),
	multiValueRemove: (provided, state) => ({
		...provided,
		color: '#ffffff',
		backgroundColor: '#efad1d',
		borderRadius: 0,
		"&:hover": {
			backgroundColor: "#efad1d",
			color: "#fff"
		}
	}),
	option: (provided, state) => ({
		...provided,
		backgroundColor: '#ffffff',
		color: '#000000',
	})
};
const DropdownIndicator = (props) => {
	return (<components.DropdownIndicator {...props}>.</components.DropdownIndicator>);
}

function MenuList(props) {
	const { options, children, maxHeight, getValue } = props;
	const [value] = getValue();
	const height = 35;
	const initialOffset = options.indexOf(value) * height;

	return (
		<FixedSizeList
			height={maxHeight}
			itemCount={children.length}
			itemSize={height}
			initialScrollOffset={initialOffset}
		>
			{({ index, style }) => <div key={index} style={style}>{children[index]}</div>}
		</FixedSizeList>
	);
}

function Sector({ match }) {
	const [data, setData] = useState({
		tickers: [],
		name: '',
	});
	const [loading, setLoading] = useState(true);
	const [selected, setSelected] = useState([]);
	const [tickerOptions, setTickerOptions] = useState([]);

	const { params: { id } } = match;
	const classes = useStyles();
	const history = useHistory();

	function fetchData() {
		Api
			.GET(`/sectors?sector=${id}`)
			.then((data) => {
				setData(data);
				setLoading(false);
				const tickers = data.tickers;
				const tickerDropdownOptions = tickers.map((ticker) => ({
					label: `${ticker[0]} - ${ticker[1]}`,
					value: ticker[0],
				}));
				setTickerOptions(tickerDropdownOptions);
			});
	}

	function handleSearch() {
		history.push(`/query/${selected.join(',')}/${id}`);
	}

	function handleSelect(event) {
		const exists = selected.filter((ticker) => ticker === event.currentTarget.id);
		if (exists.length === 0) {
			const newSelected = [
				...selected,
				event.currentTarget.id,
			];
			console.log(newSelected);
			setSelected(newSelected);
		} else {
			const newSelected = selected.filter((ticker) => ticker !== event.currentTarget.id);
			setSelected(newSelected);
		}
	}

	function handleTickerChange(data) {
		if (data.length > 0) {
			setSelected(data.map((ticker) => ticker.value));
		}
	}

	useEffect(() => {
		fetchData();
	}, []);

	return (
		<React.Fragment>
			<div className="search-form search-white px-3 bg-black text-white mb-3 bordertop borderbottom">
				<Layout.Form.Group className='form-inline row'>
					<Layout.Col className='py-md-3 py-sm-1' sm={12} md={4} lg={4}>
						<div className='inline heading align-items-start'>
							<RealState size={40} />
							<div>
								<h4 className='mb-0 text-uppercase'>{data.name}</h4>
								<span className='small'>{id}</span>
							</div>
						</div>
					</Layout.Col>
					<Layout.Col className='inline py-md-3 py-sm-1' sm={12} md={6} lg={6}>
						<label className="label-col label-inline me-2">
							<StockListings size={35} iconClass='fw-bold me-2' />
							<span className='mt-1' style={{ width: 140 }}>Stock listing(s)</span>
						</label>
						<Select
							components={{ DropdownIndicator, MenuList }}
							id='sector'
							value={selected.map((ticker) => ({
								label: ticker,
								value: ticker,
							}))}
							onChange={handleTickerChange}
							filterOption={createFilter({ ignoreAccents: false })}
							options={tickerOptions}
							styles={customStyles}
							className='form-control p-0 dropdown-green'
							isMulti
						/>
					</Layout.Col>
					<Layout.Col className='inline py-md-3 py-sm-1' sm={12} md={2} lg={2}>
						<Layout.Button
							variant="primary"
							type="submit"
							className='me-3 btn-blue'
							onClick={handleSearch}
						>
							Search
						</Layout.Button>
					</Layout.Col>
				</Layout.Form.Group>
			</div>
			<div className="sectors-list">
				<Layout.Container>
					{loading && <div className='vh-100'><CircularProgress className='text-white me-2' /></div>}
					<div className="ticker-list">
						<Layout.Row>
							{data.tickers.map((ticker) => (
								<Layout.Col key={ticker} xs={6} sm={6} md={3} lg={3}>
									<div
										id={ticker[0]}
										className={`item p-2 ${selected.includes(ticker[0]) ? 'item-selected' : 'item-normal'}`}
										onClick={handleSelect}
									>
										<h4>{ticker[0]}</h4>
										<p>{ticker[1]}</p>
									</div>
								</Layout.Col>
							))}
						</Layout.Row>
					</div>
				</Layout.Container>
			</div>
		</React.Fragment>
	);
}

export default withRouter(Sector);
