import React from 'react';

function StockListings({
    size = 20,
    color = '#fff',
    iconClass = '',
}) {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" fill={color} stroke='currentColor' strokeWidth={1} width={size} height={size} viewBox="0 0 44.43 44.43" className={iconClass}><g id="Layer_2" data-name="Layer 2"><g id="Layer_1-2" data-name="Layer 1"><polygon class="cls-1" points="42 43.63 42 19.07 41 19.07 41 43.63 35.88 43.63 35.88 15.67 34.88 15.67 34.88 43.63 29.76 43.63 29.76 11.11 28.76 11.11 28.76 43.63 23.64 43.63 23.64 16.84 22.64 16.84 22.64 43.63 17.51 43.63 17.51 23 16.51 23 16.51 43.63 11.39 43.63 11.39 18.36 10.39 18.36 10.39 43.63 5.27 43.63 5.27 11.79 4.27 11.79 4.27 43.63 0 43.63 0 44.63 46.27 44.63 46.27 43.63 42 43.63" /><polygon class="cls-1" points="32.05 1.41 42.7 12.07 37.07 10.56 36.81 11.52 45.08 13.74 42.87 5.47 41.9 5.73 43.41 11.36 32.05 0 16.78 15.27 4.24 2.74 3.54 3.44 16.78 16.69 32.05 1.41" /></g></g></svg >
    );
}

export default StockListings;