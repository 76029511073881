/**
 * @file AuthService.jsx
 * @author Duncan Grubbs <duncan.grubbs@gmail.com>
 * @description All functions related to user auth in the app
 */

import jwt from 'jsonwebtoken';

export function getToken() {
	return localStorage.getItem('token');
}

export function setToken(token) {
	localStorage.setItem('token', token);
}

export function logout() {
	localStorage.clear();
	window.location.href = '/login';
}

export function loggedIn() {
	const token = getToken();
	return token !== null;
}

export function isAdmin() {
	if (loggedIn()) {
		const token = getToken();
		const parsedToken = jwt.decode(token);

		return parsedToken.isadmin;
	}
	return false;
}
