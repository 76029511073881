import React, { useState } from 'react';
import * as Fontawesome from 'react-icons/fa';
import { logout } from '../../middleware/AuthService';
import LogoWhite from '../../images/logo_white.png';
import './styles.css';
import SearchIcon from '../../icons/SearchIcon';
import BrowserSectors from '../../icons/BrowserSectors';
import RankInnovation from '../../icons/RankInnovation';
import MyAccount from '../../icons/MyAccount';
import SupportIcon from '../../icons/SupportIcon';
import NotificationIcon from '../../icons/NotificationIcon';
import { CustomNavlink } from '../CustomNavlink';

function Topbar(props) {
    const { searchOpen, setSearchOpen, sidebarOpen, setSidebarOpen } = props;
    /*** Return Output ***/
    return (
        <div className='navbar flex-md-nowrap align-items-center'>
            <a href='/dashboard' className='navbar-brand brand-width me-2 px-3 text-center'>
                <img src={LogoWhite} alt="logo" className="logo" />
            </a>
            <a
                role='button'
                className='p-2 mobile-search'
                onClick={() => setSearchOpen(!searchOpen)}
                aria-controls="search-collapse"
                aria-expanded={searchOpen}
            >
                <SearchIcon size={35} iconClass='fs-2 me-2' />
                Search
            </a>
            {sidebarOpen ? (
                <button className='navbar-toggler position-absolute d-md-none' onClick={() => setSidebarOpen(false)}>
                    <Fontawesome.FaTimes />
                </button>
            ) : (
                <button className='navbar-toggler position-absolute d-md-none' onClick={() => setSidebarOpen(true)}>
                    <Fontawesome.FaBars />
                </button>
            )}
            <nav className='navbar py-0 d-none d-md-block' role='navigation'>
                <ul className="nav align-items-center">
                    <li className="dropdown me-2">
                        <a
                            role='button'
                            className='p-2'
                            onClick={() => setSearchOpen(!searchOpen)}
                            aria-controls="search-collapse"
                            aria-expanded={searchOpen}
                        >
                            <SearchIcon size={35} iconClass='fs-2 me-2' />
                            Search
                        </a>
                    </li>
                    <li className="dropdown me-2">
                        <CustomNavlink to='/sectors' className='p-2'>
                            <BrowserSectors size={35} iconClass='fs-2 me-2' />
                            Browse Sectors
                        </CustomNavlink>
                    </li>
                    <li className="dropdown me-2">
                        <CustomNavlink to='/search' className='p-2'>
                            <RankInnovation size={35} iconClass='fs-2 me-2' />
                            Rank Innovation
                        </CustomNavlink>
                    </li>
                </ul>
            </nav>
            <nav className='navbar py-0 d-none d-md-block' role='navigationi'>
                <ul className="nav align-items-center">
                    <li className="dropdown me-2">
                        <a href='' className='p-2 text-danger'>
                            <NotificationIcon iconClass='fs-2' />
                        </a>
                    </li>
                    <li className="dropdown me-2">
                        <a href='' className='p-2'>
                            <SupportIcon iconClass='fs-2' />
                        </a>
                    </li>
                    <li className="dropdown me-2">
                        <a role='button' className='p-2' onClick={() => logout()}><MyAccount iconClass='fs-2 me-1' /> Logout</a>
                    </li>
                </ul>
            </nav>
        </div>
    );
}

export default Topbar;