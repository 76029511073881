import React from 'react';

const BrowserSectors = ({
    iconClass,
    size = 20,
}) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" stroke='currentColor' strokeWidth={1} width={size} height={size} viewBox="0 -1 40.95 46.56" className={iconClass}><g id="Layer_2" data-name="Layer 2"><g id="Layer_1-2" data-name="Layer 1"><path class="cls-1" d="M19.24,31.65l21-16.86L39.65,14,19.07,30.51,1,23.36V18L19.24,25.2,41,7.8,21.24,0,0,15.08V37l19.24,7.61,21-16.86-.63-.78L19.07,43.42,1,36.27V30.89l18.24,7.22,21-16.86-.63-.78L19.07,37,1,29.82V24.44ZM1,15.6,21.38,1.13l17.6,7-19.91,16L1,16.9Z"/></g></g></svg>
    );
}

export default BrowserSectors;