import React from 'react';
import * as Layout from 'react-bootstrap';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormLabel from '@mui/material/FormLabel';
import Checkbox from '@material-ui/core/Checkbox';
import { withStyles } from '@material-ui/core/styles';
import Logo from '../../images/logo.png';

const checkBoxStyles = theme => ({
    root: {
        color: '#000000',
        fontSize: '2rem',
        '&$checked': {
            color: '#00703d',
        },
    },
    checked: {},
})
const CustomCheckbox = withStyles(checkBoxStyles)(Checkbox);

const Register = () => {
    return (
        <Layout.Row className='justify-content-md-center my-4'>
            <Layout.Col className='col-md-5'>
                <div className='box-wrapper register'>
                    <div className='w-75 mx-auto mb-3'>
                        <img src={Logo} alt="company logo" className='w-100' />
                    </div>
                    <p className='text-center mb-3 login-text mb-0'>SIGN-UP</p>
                    <p className='text-center text-muted mb-0' style={{ fontSize: 16, fontWeight: 200 }}>Create your Innovation Check account</p>
                    <p className='text-center text-muted mb-4' style={{ fontSize: 16, fontWeight: 200 }}>Already have an account? <a href='/login' className='text-green' style={{ fontWeight: 400 }}>Login</a></p>
                    <Layout.Form>
                        <Layout.Form.Group className='mb-3' controlId='frmFirstName'>
                            <Layout.Form.Control
                                size="lg"
                                type='text'
                                name='first_name'
                                placeholder='First Name'
                            />
                        </Layout.Form.Group>
                        <Layout.Form.Group className='mb-3' controlId='frmLastName'>
                            <Layout.Form.Control
                                size="lg"
                                type='text'
                                name='last_name'
                                placeholder='Last Name'
                            />
                        </Layout.Form.Group>
                        <Layout.Form.Group className='mb-3' controlId='frmEmail'>
                            <Layout.Form.Control
                                size="lg"
                                type='email'
                                name='email'
                                placeholder='Email'
                            />
                        </Layout.Form.Group>
                        <Layout.Form.Group className='mb-3' controlId='frmPassword'>
                            <Layout.Form.Control
                                size="lg"
                                type='password'
                                name='password'
                                placeholder='Password'
                                autoComplete='off'
                            />
                        </Layout.Form.Group>
                        <Layout.Form.Group className='mb-3' controlId='frmConfirmPassword'>
                            <Layout.Form.Control
                                size="lg"
                                type='password'
                                name='confirm_password'
                                placeholder='Confirm password'
                                autoComplete='off'
                            />
                        </Layout.Form.Group>
                        <Layout.Form.Group className='mb-3'>
                            <p className='text-center text-muted' style={{ fontSize: 15 }}>How will you be using this application?</p>
                        </Layout.Form.Group>
                        <Layout.Form.Group className='mb-3' controlId='application_type'>
                            <Layout.Form.Select
                                size="lg"
                                id="application_type"
                                name="application_type"
                                className='select1'
                            >
                                <option value="">Please select Option</option>
                                <option value="1">Option-1</option>
                            </Layout.Form.Select>
                        </Layout.Form.Group>
                        <Layout.Form.Group className='mb-3' controlId='frmCheckbox'>
                            <FormLabel>
                                <CustomCheckbox defaultChecked />
                                <label style={{ fontSize: 12.506, fontWeight: 200 }}>I agree to the <span className='text-green2'>Terms of Service</span></label>
                            </FormLabel>
                            <FormLabel>
                                <CustomCheckbox />
                                <label style={{ fontSize: 12.506, fontWeight: 200 }}>I agree to the <span className='text-green2'>Privacy Policy</span></label>
                            </FormLabel>
                        </Layout.Form.Group>
                        <Layout.Form.Group className='mb-3'>
                            <Layout.Button type='submit' variant='primary' className='w-100 btn-green py-2' style={{ fontSize: 15, fontWeight: 100 }}>Create Account</Layout.Button>
                        </Layout.Form.Group>
                    </Layout.Form>
                </div>
            </Layout.Col>
        </Layout.Row>
    );
}

export default Register;