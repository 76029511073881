import React from 'react';

function CompanyStatisticsIcon({
    iconClass,
    size = 20
}) {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" stroke='currentColor' strokeWidth={1} width={size} height={size} viewBox="0 0 47.81 44.53" className={iconClass}><g id="Layer_2" data-name="Layer 2"><g id="Layer_1-2" data-name="Layer 1"><path class="cls-1" d="M44.72,43.53V16.44l-5.61-5.85L33.9,13V5.85L28.29,0,13.9,6.57v7.1L3.09,18.6V43.53H0v1H47.81v-1Zm-1,0H39.5V12.43l4.22,4.41ZM38.5,12V43.53H33.9V14.06ZM32.9,43.53H28.68V1.85l4.22,4.4ZM14.9,7.21,27.68,1.38V43.53H25.45V17.78L19.2,11.25l-4.3,2Zm4.68,5.89,4.87,5.09V43.53H19.58ZM4.09,19.24l14.49-6.6V43.53H4.09Z" /><rect class="cls-1" x="7.42" y="38.2" width="7.83" height="1" /><rect class="cls-1" x="7.42" y="32.87" width="7.83" height="1" /><rect class="cls-1" x="7.42" y="27.54" width="7.83" height="1" /><rect class="cls-1" x="7.42" y="22.21" width="7.83" height="1" /></g></g></svg>
    );
}

export default CompanyStatisticsIcon;