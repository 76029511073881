import React from 'react';

function SelectedSentancesIcon({
    iconClass,
    size = 20
}) {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" stroke='currentColor' strokeWidth={1} width={size} height={size} viewBox="0 0 31.94 44.43" className={iconClass}><g id="Layer_2" data-name="Layer 2"><g id="Layer_1-2" data-name="Layer 1"><path class="cls-1" d="M23.78,4.85A1.68,1.68,0,0,1,26,4.33l.5-.86A2.67,2.67,0,0,0,23,4.3L10.69,22.72l.83.55Z" /><path class="cls-1" d="M9.32,39.7l1,.59L12,37.34a2.36,2.36,0,0,1,1.85-1.17,6.85,6.85,0,0,0,5-2.93l.05,0,.23-.47.25-.41,0,0,12-23.65a5.38,5.38,0,0,0-2.09-7.06L27.68.73A5.37,5.37,0,0,0,20.5,2.4L5.82,24.45h0l-.24.42-.29.43.06,0a6.79,6.79,0,0,0-.08,5.81,2.37,2.37,0,0,1-.11,2.18L3.41,36.26l1,.59L0,44.43H31.44v-1H7.15ZM6.68,25,21.33,3a4.38,4.38,0,0,1,5.84-1.36l1.6.93a4.39,4.39,0,0,1,1.7,5.75L18.55,31.87l-5.94-3.46ZM6,33.81a3.39,3.39,0,0,0,.15-3.1,5.8,5.8,0,0,1,0-4.89l11.88,6.92a5.8,5.8,0,0,1-4.25,2.43,3.4,3.4,0,0,0-2.62,1.66L10,38.92l-.14-.08h0L7.37,37.41,4.78,35.9ZM1.74,43.43l3.54-6.08,1.59.93,1.59.92L6,43.43Z" /></g></g></svg>
    );
}

export default SelectedSentancesIcon;