import React, { useState } from 'react';
import * as Layout from 'react-bootstrap';
import SubscriptionModal from '../../components/Sidebar/SubscriptionModal';
import './styles.css';

const SubscriptionPlans = () => {
    const [modalShow, setModalShow] = useState(false);
    const subscriptionPlans = [
        {
            title: 'Monthly Plan',
            sub_title: 'Ideal solution for beginners',
            amount: 10
        },
        {
            title: 'Premium Plan',
            sub_title: 'Perfect package for your need',
            amount: 20
        },
        {
            title: 'Business Plan',
            sub_title: 'Optimized for small and medium businesses',
            amount: 25
        }
    ];
    return (
        <React.Fragment>
            <div className="p-4">
                <h1 className='mb-4 mt-4 text-white text-center' style={{ fontSize: 36, fontWeight: 700 }}>Subscription Plans</h1>
            </div>
            <Layout.Container fluid className='mb-4'>
                <Layout.Row>
                    {subscriptionPlans.map((item, index) => (
                        <Layout.Col key={index} sm={12} md={4}>
                            <Layout.Card className='py-5 mx-3 text-center'>
                                <Layout.Card.Title>{item.title}</Layout.Card.Title>
                                <Layout.Card.Body>
                                    <p>{item.sub_title}</p>
                                    <div className='d-flex flex-wrap justify-content-center pricing-table'>
                                        <span className='currency-symbol'>$</span>
                                        <span className='currency'>{item.amount}</span>
                                        <span className='currency-plan'>/mo</span>
                                    </div>
                                    <div className='mt-40'>
                                        <Layout.Button variant='primary btn-blue text-white border-0 rounded w-75' onClick={() => setModalShow(true)}>
                                            Subscribe Now
                                        </Layout.Button>
                                    </div>
                                    <div className='mt-4'>
                                        <p>Top features</p>
                                        <ul className='list'>
                                            <li>Lorem Ipsum is simply dummy text of the printing and typesetting industry.</li>
                                            <li>Lorem Ipsum is simply dummy text of the printing and typesetting industry.</li>
                                            <li>Lorem Ipsum is simply dummy text of the printing and typesetting industry.</li>
                                        </ul>
                                    </div>
                                </Layout.Card.Body>
                            </Layout.Card>
                        </Layout.Col>
                    ))}
                </Layout.Row>
            </Layout.Container>
            <SubscriptionModal
                show={modalShow}
                onHide={() => setModalShow(false)}
            />
        </React.Fragment>
    );
};

export default SubscriptionPlans;