import React, { useState, useEffect } from 'react';
import Select, { createFilter, components } from 'react-select';
import { useHistory } from 'react-router-dom';
import * as Layout from 'react-bootstrap';
import { FixedSizeList } from 'react-window';
import Api from '../middleware/Api';
import log from '../util/logger';
import ic_searchresult1 from '../images/ic_searchresult1.png';
import ic_searchresult2 from '../images/ic_searchresult2.png';
import arrow_icon from '../images/arrow_icon.png';
import SectorSearchIcon from '../icons/SectorSearchIcon';
import StockListings from '../icons/StockListings';

function MenuList(props) {
    const { options, children, maxHeight, getValue } = props;
    const [value] = getValue();
    const height = 35;
    const initialOffset = options.indexOf(value) * height;

    return (
        <FixedSizeList
            height={maxHeight}
            itemCount={children.length}
            itemSize={height}
            initialScrollOffset={initialOffset}
        >
            {({ index, style }) => <div style={style}>{children[index]}</div>}
        </FixedSizeList>
    );
}
const customStyles = {
    control: (provided, state) => ({
        ...provided,
        backgroundColor: '#000000',
        boxShadow: 'none',
        borderColor: "#fff",
        "&:hover": {
            borderColor: "#fff",
        }
    }),
    singleValue: (provided, state) => ({
        ...provided,
        color: '#ffffff',
    }),
    indicatorsContainer: (provided, state) => ({
        ...provided,
        padding: 2,
    }),
    indicatorSeparator: () => ({
        width: 0,
    }),
    dropdownIndicator: (provided, state) => ({
        transition: 'color 150ms',
        boxSizing: 'border-box',
        display: 'flex',
        padding: 8,
        color: '#ffffff',
        textIndent: '-9999px',
        width: 35,
        backgroundColor: '#0065a4',
        backgroundPosition: '0 0',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        backgroundImage: `url(${arrow_icon})`,
    }),
    multiValueLabel: (provided, state) => ({
        ...provided,
        color: '#ffffff',
        backgroundColor: '#efad1d',
        borderRadius: 0,
    }),
    multiValueRemove: (provided, state) => ({
        ...provided,
        color: '#ffffff',
        backgroundColor: '#efad1d',
        borderRadius: 0,
        "&:hover": {
            backgroundColor: "#efad1d",
            color: "#fff"
        }
    }),
    option: (provided, state) => ({
        ...provided,
        backgroundColor: '#ffffff',
        color: '#000000',
    })
};
const DropdownIndicator = (props) => {
    return (<components.DropdownIndicator {...props}>.</components.DropdownIndicator>);
}

const SearchForm = (props) => {
    const history = useHistory();
    const [data, setData] = useState({});
    const { tickers, selectedSector } = props;
    const [documentLoading, setDocumentLoading] = useState(false);
    const [tickerOptions, setTickerOptions] = useState([]);
    const [baseTickerOptions, setBaseTickerOptions] = useState([]);
    const [sectorOptions, setSectorOptions] = useState([]);
    const [resetSector, setResetSector] = useState('');
    const [isSector, setIsSector] = useState('');
    const [sector, setSector] = useState(resetSector !== '' || selectedSector || 'None');
    const [sectorMap, setSectorMap] = useState({});
    const [selectedTickers, setTickers] = useState(
        tickers ? tickers.split(',') : []
    );
    const [checkedState, setCheckedState] = useState({
        indexscore: true,
        sd: false,
    });
    const [change, setChange] = useState(null);
    const [chartTitle, setChartTitle] = useState('');
    let selectRef = null;
    let selectRefSector = 'None';
    // reset page values function
    async function resetPageValues() {
        setIsSector('None');
        selectRefSector.select.clearValue();
        await selectRef.select.clearValue();
        await document.getElementById('submitHandler').click();
        setIsSector('');
    }
    function handleSectorChange(data) {
        if (data === null) {
            setCheckedState({ ...checkedState, sd: false });
            setTickerOptions(baseTickerOptions);
        } else {
            const { value } = data;
            setSector(value);

            if (value !== 'None') {
                const filteredTickers = filterTickersBySector(
                    value,
                    sectorMap,
                    baseTickerOptions
                );

                setTickers([]);
                setTickerOptions(filteredTickers);
            }

            if (value === 'None') {
                setCheckedState({ ...checkedState, sd: false });
                setTickerOptions(baseTickerOptions);
            }
        }
    }
    function handleTickerChange(data) {
        data.length !== 0 && setChange(data.map((ticker) => ticker.value));
        setTickers(data.map((ticker) => ticker.value));
    }
    function handleSubmit(event) {
        event.preventDefault();
        if (sector !== 'None' && sector !== '') {
            window.location = `/query/${selectedTickers.join(',')}/${sector}`;
        } else {
            window.location = `/query/${selectedTickers.join(',')}`;
        }
    }
    function filterTickersBySector(sector, sectorMap, tickersToFilter) {
        if (sector === 'None') return tickersToFilter;

        const validTickers = sectorMap[sector].tickers.map((ticker) => ticker[0]);

        const filteredTickers = tickersToFilter.filter((ticker) =>
            validTickers.includes(ticker.value)
        );

        return filteredTickers;
    }
    function fetchDataPoints() {
        //setChartLoading(true);

        let queryString = `/companies?tickers=${selectedTickers.join(',')}`;
        if (sector !== '' && sector !== 'None') {
            queryString = `/companies?tickers=${selectedTickers.join(
                ','
            )}&sector=${sector}`;
        }

        Api.GET(queryString)
            .then((payload) => {
                setData(payload);
                setChartTitle(selectedTickers.join(', '));
                //setChartLoading(false);
                let test = Object.entries(payload);
                test[0][1][0].id !== idCheck && (match.params.tickers === change.toString() && loadFulltext(test[0][1][0].id));
                setIdCheck(test[0][1][0].id);
            })
            .catch((error) => {
                //setChartLoading(false);
                log(error);
            });
    }
    async function fetchDropdownData() {
        const sectors = await Api.GET('/sectors').catch((error) => {
            log(error);
        });

        let sectorList = Object.keys(sectors)
            .sort()
            .map((sector) => ({
                label: `${sector} - ${sectors[sector].name}`,
                value: sector,
            }));

        sectorList = [{ label: 'None', value: 'None' }, ...sectorList];

        setSectorMap(sectors);
        setSectorOptions(sectorList);

        const tickers = await Api.GET('/companies/tickers').catch((error) => {
            log(error);
        });

        const tickerDropdownOptions = Object.keys(tickers)
            .sort()
            .map((ticker) => ({
                label: `${ticker} - ${tickers[ticker][2]}`,
                value: ticker,
            }));

        if (sector !== 'None' && sector !== '') {
            const filteredTickers = filterTickersBySector(
                sector,
                sectors,
                tickerDropdownOptions
            );
            setTickerOptions(filteredTickers);
        } else {
            setTickerOptions(tickerDropdownOptions);
        }

        setBaseTickerOptions(tickerDropdownOptions);
    }
    useEffect(() => {
        fetchDataPoints();
    }, [tickers]);

    useEffect(() => {
        fetchDropdownData();
    }, []);

    return (
        <form
            noValidate
            onSubmit={handleSubmit}
            autoComplete="off"
            className='form-inline row'
        >
            <Layout.Col className='inline py-md-3 py-sm-1' sm={12} md={4}>
                <label className="label-col label-inline me-2">
                    <SectorSearchIcon size={35} iconClass='me-2' />
                    Sectors
                </label>
                <Select
                    ref={ref => {
                        selectRefSector = ref;
                    }}
                    components={{ DropdownIndicator }}
                    id="sector"
                    onChange={handleSectorChange}
                    options={sectorOptions}
                    value={isSector !== 'None' ? { label: sector, value: sector } : {
                        label: 'None',
                        value: 'None'
                    }}
                    styles={customStyles}
                    className="form-control p-0 dropdown-green"
                />
            </Layout.Col>
            <Layout.Col className='inline py-md-3 py-sm-1' sm={12} md={6}>
                <label className="label-col label-inline me-2">
                    <StockListings size={35} iconClass='me-2' />
                    <span style={{ width: 135 }}>Stock listing(s)</span>
                </label>
                <Select
                    ref={ref => {
                        selectRef = ref;
                    }}
                    id="tickers"
                    onChange={handleTickerChange}
                    filterOption={createFilter({ ignoreAccents: false })}
                    value={selectedTickers.map((ticker) => ({
                        label: ticker,
                        value: ticker,
                    }))}
                    options={tickerOptions}
                    components={{ DropdownIndicator, MenuList }}
                    styles={customStyles}
                    className='form-control p-0 dropdown-green'
                    isMulti
                />
            </Layout.Col>
            <Layout.Col className='inline btn-mobile py-md-3 py-sm-1' sm={12} md={2}>
                <Layout.Button
                    id={'submitHandler'}
                    variant="primary"
                    type="submit"
                    className='me-3 btn-blue'
                >
                    Search
                </Layout.Button>
                <Layout.Button
                    disabled={documentLoading}
                    variant="success"
                    type="submit"
                    className='me-3 btn-green'
                    onClick={() => resetPageValues()}
                >
                    Reset
                </Layout.Button>
            </Layout.Col>
        </form>
    );
}

export default SearchForm;