import React, { useState } from 'react';
import * as Layout from 'react-bootstrap';
import { loggedIn } from '../middleware/AuthService';
import SearchForm from './SearchForm';
import Offcanvas from './Offcanvas';
import Sidebar from './Sidebar/Sidebar';
import Topbar from './Topbar/Topbar';

const Template = ({ children }) => {
    const [searchOpen, setSearchOpen] = useState(false);
    const [sidebarOpen, setSidebarOpen] = useState(false);
    return (
        <React.Fragment>
            {
                loggedIn() ? (
                    <div className='app-wrapper'>
                        <div className="app-content" >
                            <header className='fixed-top bg-black p-1'>
                                <Topbar
                                    searchOpen={searchOpen}
                                    setSearchOpen={setSearchOpen}
                                    sidebarOpen={sidebarOpen}
                                    setSidebarOpen={setSidebarOpen}
                                />
                                <Layout.Collapse in={searchOpen} timeout={100}>
                                    <div className="search-collapse px-3">
                                        <SearchForm />
                                    </div>
                                </Layout.Collapse>
                            </header>
                            <div className="app-body">
                                <Sidebar
                                    searchOpen={searchOpen}
                                    setSearchOpen={setSearchOpen}
                                />
                                <main>
                                    {children}
                                </main>
                            </div>
                            <Offcanvas
                                show={sidebarOpen}
                                onHide={() => setSidebarOpen(false)}
                                searchOpen={searchOpen}
                                setSearchOpen={setSearchOpen}
                            />
                        </div >
                    </div>
                ) : (
                    <div className='app-wrapper'>
                        <div className="container-fluid app-content">
                            {children}
                        </div>
                    </div>
                )}
        </React.Fragment>
    );
}

export default Template;