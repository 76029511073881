import React from 'react';
import * as Layout from 'react-bootstrap';
import Sidebar from './Sidebar/Sidebar';
import LogoWhite from '../images/logo_white.png';

const Offcanvas = (props) => {
    return (
        <Layout.Offcanvas
            show={props.show}
            onHide={props.onHide}
            name="Sidebar Canvas"
            backdrop={true}
            scroll={true}
        >
            <Layout.Offcanvas.Header closeButton>
                <Layout.Offcanvas.Title>
                    <img src={LogoWhite} alt="logo" className="logo" />
                </Layout.Offcanvas.Title>
            </Layout.Offcanvas.Header>
            <Layout.Offcanvas.Body>
                <Sidebar {...props} />
            </Layout.Offcanvas.Body>
        </Layout.Offcanvas>
    );
}

export default Offcanvas;