/**
 * @file Login.jsx
 * @author Duncan Grubbs <duncan.grubbs@gmail.com>
 * @description Login route for users to provide a pin and email
 */

import React from 'react';
import { useHistory } from 'react-router-dom';
import * as Layout from 'react-bootstrap';
import CircularProgress from '@material-ui/core/CircularProgress';

import log from '../../util/logger';
import Api from '../../middleware/Api';
import { setToken } from '../../middleware/AuthService';
import Logo from '../../images/logo.png';

const Login = () => {
	const [email, setEmail] = React.useState();
	const [pin, setPin] = React.useState();
	const [loading, setLoading] = React.useState(false);

	const history = useHistory();

	const handleEmailChange = (event) => setEmail(event.target.value);
	const handlePinChange = (event) => setPin(event.target.value);

	function login(event) {
		event.preventDefault();
		setLoading(true);
		Api
			.POST('/users/login', {
				email,
				pin,
			})
			.then((data) => {
				setToken(data.token);
				//history.push('/dashboard');
				window.location = '/dashboard';
			})
			.catch((error) => {
				setLoading(false);
				log(error);
			});
	}

	return (
		<Layout.Row className='justify-content-md-center mt-4'>
			<Layout.Col className='col-md-5'>
				<div className='box-wrapper login-box'>
					<div className='w-75 mx-auto mb-3'>
						<img src={Logo} alt="company logo" className='w-100' />
					</div>
					<p className='text-center mb-3 login-text'>LOGIN</p>
					<p className='text-center text-muted mb-0' style={{ fontSize: 16, fontWeight: 200 }}>Login to your Innovation Check account.</p>
					<p className='text-center text-muted mb-4' style={{ fontSize: 16, fontWeight: 200 }}>Don't have an account? <a href='/register' className='text-green' style={{ fontWeight: 400 }}>Sign Up</a></p>
					<Layout.Form className='form-login' onSubmit={login} autoComplete='off'>
						<Layout.Form.Group className='mb-3'>
							<Layout.Form.Control
								id='email'
								name='email'
								type='email'
								onChange={handleEmailChange}
								placeholder='Email'
							/>
						</Layout.Form.Group>
						<Layout.Form.Group className='mb-3'>
							<Layout.Form.Control
								id='pin'
								name='pin'
								type='password'
								className='i-password'
								onChange={handlePinChange}
								placeholder='Password'
							/>
						</Layout.Form.Group>
						<Layout.Form.Group className='mb-3'>
							<a href='' className='d-flex justify-content-end text-green' style={{ fontSize: 12.506 }}>Forgot your password?</a>
						</Layout.Form.Group>
						<Layout.Form.Group className='mb-5'>
							<Layout.Button type='submit' variant='primary' className='w-100 btn-green py-2' style={{ fontSize: 15 }}>Login</Layout.Button>
						</Layout.Form.Group>
					</Layout.Form>
					{loading && <CircularProgress />}
				</div>
			</Layout.Col>
		</Layout.Row>
	);
}

export default Login;
