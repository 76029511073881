import React from 'react';
import { withRouter, useHistory } from 'react-router-dom';
import * as Layout from 'react-bootstrap';
import './styles.css';
import LatestNews from '../../icons/LatestNews';
import LatestEarningsCalls from '../../icons/LatestEarningsCalls';

function Dashboard() {
    return (
        <React.Fragment>
            <div className="welcome-note p-4">
                <h1 className='mb-4 mt-4' style={{ fontSize: 36, fontWeight: 700 }}>Welcome to Innovation Check!</h1>
                <p className='mb-0' style={{ fontSize: 22, fontWeight: 300 }}>Successful companies embrace innovation as its the common denominator to distinguish itself.</p>
                <p style={{ fontSize: 22, fontWeight: 300 }}>We have one mission here, and its to amplify your power to discover innovation.</p>
            </div>
            <div className="box-content dashboard">
                <div className="box-item">
                    <div className="box-title px-4 pb-3 pt-4">
                        <h1 className='d-flex align-items-baseline fs-2 mb-0'>
                            Latest Earnings Calls
                            <LatestEarningsCalls iconClass='fs-11 ms-3' />
                        </h1>
                    </div>
                    <Layout.Container fluid>
                        <Layout.Row>
                            <Layout.Col>
                                <div className='p-3'>
                                    <Layout.Table responsive size="md">
                                        <thead>
                                            <tr>
                                                <th>SECTOR</th>
                                                <th>SYMBOL</th>
                                                <th>COMPANY NAME</th>
                                                <th>DATE</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {[...Array(8)].map((item, key) => (
                                                <tr key={key}>
                                                    <td>Real Estate</td>
                                                    <td>SLG</td>
                                                    <td>SL Green Realty Crop</td>
                                                    <td>04/21/2022</td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </Layout.Table>
                                </div>
                            </Layout.Col>
                        </Layout.Row>
                    </Layout.Container>
                </div>
                <div className="box-item">
                    <div className="box-title px-4 pb-3 pt-4">
                        <div className='d-flex align-items-baseline'>
                            <h1 className='d-flex align-items-baseline fs-2 mb-0 position-relative' style={{marginTop:15}}>
                                News
                                <LatestNews iconClass='fs-11 ms-3 mbn' />
                            </h1>
                        </div>
                    </div>
                    <Layout.Container fluid>
                        <Layout.Row>
                            <Layout.Col>
                                <div className="p-3">
                                    <b className='d-block fs-5 fw-light'>Lorem ipsum dolor sit amet, consectetur adipiscing</b>
                                    <p className='mt-3 text-muted lh-lg'>Sed pharetra eget libero eu cursus. Maecenas vitae dolor id diam euismod honcus. Phasellus viverra velit sed ligula cursus suscipit. Phasellus ex risus, commodo id purus. Etiam a justo vel mi faucibus tincidunt. </p>
                                    <a href='' className='read-more'>Read More</a>
                                </div>
                                <hr />
                                <div className="p-3">
                                    <b className='d-block fs-5 fw-light'>Lorem ipsum dolor sit amet, consectetur adipiscing</b>
                                    <p className='mt-3 text-muted lh-lg'>Sed pharetra eget libero eu cursus. Maecenas vitae dolor id diam euismod honcus. Phasellus viverra velit sed ligula cursus suscipit. Phasellus ex risus, commodo id purus. Etiam a justo vel mi faucibus tincidunt.</p>
                                    <a href='' className='read-more'>Read More</a>
                                </div>
                            </Layout.Col>
                        </Layout.Row>
                    </Layout.Container>
                </div>
            </div>
        </React.Fragment>
    );
}

export default withRouter(Dashboard);