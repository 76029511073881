/**
 * @file ProtectedRoute.jsx
 * @author Duncan Grubbs <duncan.grubbs@gmail.com>
 * @description Checks whether user is logged in our not and
 * redirects to /login if they are not logged in
 */

import React from 'react';

import { Redirect, Route } from 'react-router-dom';
import { loggedIn, isAdmin } from '../middleware/AuthService';

const ProtectedRoute = ({
	children,
	authenticationPath,
	exact,
	admin = false,
	path,
}) => {
	const exactValue = exact || false;
	const needsAdmin = !admin || (admin && isAdmin());

	const routeComponent = () => (
		loggedIn() && needsAdmin
			? children
			: <Redirect to={{ pathname: authenticationPath }} />
	);
	return <Route exact={exactValue} path={path}>{ routeComponent }</Route>;
};

export default ProtectedRoute;
