/**
 * @file App.js
 * @author Duncan Grubbs <duncan.grubbs@gmail.com>
 * @description Application wrapper file, no core functionality
 */

import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import Template from './components/Template';
import AppRoutes from './routes';

const App = (props) => {
	return (
		<BrowserRouter>
			<Template {...props}>
				<AppRoutes />
			</Template>
		</BrowserRouter>
	);
}

export default App;
