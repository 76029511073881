import React from 'react';

function SectorSearchIcon({
    iconClass,
    size = 20
}) {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" fill="currentColor" stroke='currentColor' strokeWidth={1} width={size} height={size} viewBox="0 0 54.21 44.43" className={iconClass}><g id="Layer_2" data-name="Layer 2"><g id="Layer_1-2" data-name="Layer 1"><path class="cls-1" d="M49.28,24a22.62,22.62,0,0,0-1.38-5.23l3.87-3.09-2.41-4.17-4.64,1.82a22.45,22.45,0,0,0-3.63-3.66L43,5,38.82,2.54,35.68,6.4a22.12,22.12,0,0,0-5.43-1.47L29.51,0H24.69L24,4.93A22.16,22.16,0,0,0,18.53,6.4L15.39,2.54,11.24,5l1.87,4.63a22.39,22.39,0,0,0-3.62,3.66L4.84,11.46,2.43,15.63l3.88,3.09A22.13,22.13,0,0,0,4.93,24L0,24.69v4.39H1V25.56l4.82-.73,0-.38a21,21,0,0,1,1.51-5.69l.14-.35-3.79-3L5.28,12.7l4.54,1.79.23-.3a21.54,21.54,0,0,1,4-4l.3-.23L12.49,5.42l2.66-1.58,3.08,3.78.35-.16a21.15,21.15,0,0,1,5.87-1.59l.38,0L25.55,1h3.1l.73,4.82.37,0a21,21,0,0,1,5.88,1.6l.35.15,3.07-3.78,2.66,1.58L39.88,10l.3.23a21.54,21.54,0,0,1,4,4l.23.3,4.55-1.79,1.55,2.68-3.8,3,.15.35a21.39,21.39,0,0,1,1.5,5.69l.05.38,4.83.73v3.52h1V24.69Z" /><path class="cls-1" d="M27.1,14.34a12.75,12.75,0,0,0-.5,25.5v4.59h.5A17.34,17.34,0,0,0,44.43,27.1v-.5H39.84A12.77,12.77,0,0,0,27.1,14.34ZM15.34,27.1a11.76,11.76,0,0,1,23.5-.5H26.6V38.84A11.76,11.76,0,0,1,15.34,27.1ZM27.6,43.42V27.6H43.42A16.34,16.34,0,0,1,27.6,43.42Z" /></g></g></svg>
    );
}

export default SectorSearchIcon;