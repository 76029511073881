import React from 'react';
import * as Layout from 'react-bootstrap';
import * as Fontawesome from 'react-icons/fa';
import './styles.css';

const SubscriptionModal = (props) => {
    return (
        <Layout.Modal
            {...props}
            contentClassName='subscription-modal'
        >
            <Layout.ModalBody>
                <div className="text-center p-4">
                    <div className='icon-box mb-3'>
                        <div className="exclamation-icon">
                            <Fontawesome.FaExclamation size={35} color='#e3b250' />
                        </div>
                    </div>
                    <h1 className='heading mb-4'>Subscription Notice</h1>
                    <p className='mb-5'>Your free trial ends in 10 days. Subscribe now to avoid <br />losing your innovation insights.</p>
                    <div className='w-75 mx-auto'>
                        <Layout.Button className='w-100' variant='warning'>Subscribe Now</Layout.Button>
                    </div>
                </div>
            </Layout.ModalBody>
        </Layout.Modal>
    );
}

export default SubscriptionModal;