/**
 * @file index.jsx
 * @author Duncan Grubbs <duncan.grubbs@gmail.com>
 * @description Entry point for the react router
 */

import React from 'react';
import { Redirect, Route, Switch, BrowserRouter } from 'react-router-dom';
import Admin from './Admin/Admin';
import Home from './Home/Home';
import Login from './Login/Login';
import Dashboard from './Dashboard/Dashboard';
import Register from './Register/Register';
import Sector from './Sectors/Sector';
import Sectors from './Sectors/Sectors';
import SearchAndDisplay from './SearchAndDisplay/SearchAndDisplay';
import SubscriptionPlans from './Subscription/SubscriptionPlans';

import ProtectedRoute from '../components/ProtectedRoute';

const AppRoutes = (props) => {
	return (
		<BrowserRouter {...props}>
			<Switch>
				<Route exact
					path="/login">
					<Login />
				</Route>

				<Route exact
					path="/register">
					<Register />
				</Route>

				<ProtectedRoute exact
					authenticationPath="/login"
					path="/dashboard">
					<Dashboard />
				</ProtectedRoute>

				<ProtectedRoute exact
					authenticationPath="/login"
					path="/query/:tickers">
					<Home />
				</ProtectedRoute>

				<ProtectedRoute exact
					authenticationPath="/login"
					path="/query/:tickers/:selectedSector">
					<Home />
				</ProtectedRoute>

				<ProtectedRoute exact
					authenticationPath="/login"
					path="/search">
					<SearchAndDisplay />
				</ProtectedRoute>

				<ProtectedRoute exact
					authenticationPath="/login"
					path="/">
					<Home />
				</ProtectedRoute>

				<ProtectedRoute exact
					authenticationPath="/"
					admin
					path="/admin">
					<Admin />
				</ProtectedRoute>

				<ProtectedRoute exact
					authenticationPath="/login"
					path="/sector/:id">
					<Sector />
				</ProtectedRoute>

				<ProtectedRoute exact
					authenticationPath="/login"
					path="/sectors">
					<Sectors />
				</ProtectedRoute>

				<ProtectedRoute exact
					authenticationPath="/login"
					path="/subscription">
					<SubscriptionPlans />
				</ProtectedRoute>

				<ProtectedRoute exact
					authenticationPath="/login"
					path="*">
					<Redirect to="/" />
				</ProtectedRoute>
			</Switch>
		</BrowserRouter>
	);
}

export default AppRoutes;
