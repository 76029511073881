import React, { useState } from 'react';
import * as Fontawesome from 'react-icons/fa';
import { logout } from '../../middleware/AuthService';
import profileImg from '../../images/img_profile.png';
import './styles.css'
import SubscriptionModal from './SubscriptionModal';
import DashboardIcon from '../../icons/DashboardIcon';
import SubscriptionIcon from '../../icons/SubscriptionIcon';
import ProfileIcon from '../../icons/ProfileIcon';
import SearchIcon from '../../icons/SearchIcon';
import BrowserSectors from '../../icons/BrowserSectors';
import RankInnovation from '../../icons/RankInnovation';
import NotificationIcon from '../../icons/NotificationIcon';
import SupportIcon from '../../icons/SupportIcon';
import MyAccount from '../../icons/MyAccount';
import { CustomNavlink } from '../CustomNavlink';

function Sidebar(props) {
    const { searchOpen, setSearchOpen } = props;
    const [modalShow, setModalShow] = useState(false);
    const [activeClass, setActiveClass] = useState('dashboard');
    const handleActive = (link) => {
        setActiveClass(link);
    }
    return (
        <React.Fragment>
            <div className={`sidebar sidebar-width ${props.show ? 'd-block' : 'd-none d-md-block'}`}>
                <div className="sidebar-nav">
                    <div className="company-logo">
                        <img src={profileImg} alt="user" className="img logo rounded-circle mb-1" />
                        <p className='mb-0' style={{ fontSize: 12, fontWeight: 400 }}>John Smith</p>
                        <p className='mb-0' style={{ fontSize: 12, fontWeight: 400 }}><small>Company Name</small></p>
                    </div>
                    <ul className="nav">
                        <li className='nav-item mobile'>
                            <a
                                role='button'
                                className='nav-link'
                                onClick={() => setSearchOpen(!searchOpen)}
                                aria-controls="search-collapse"
                                aria-expanded={searchOpen}
                            >
                                <SearchIcon size={35} iconClass='fs-2 me-2' />
                                Search
                            </a>
                        </li>
                        <li className='nav-item mobile'>
                            <a href="/sectors" className="nav-link">
                                <BrowserSectors size={25} iconClass='fs-3 me-2' />
                                Browse Sectors
                            </a>
                        </li>
                        <li className='nav-item mobile'>
                            <a href="/search" className="nav-link">
                                <RankInnovation size={25} iconClass='fs-3 me-2' />
                                Rank Innovation
                            </a>
                        </li>
                        <li className="nav-item dashboard" onClick={() => handleActive('dashboard')}>
                            <CustomNavlink to="/dashboard" className="nav-link">
                                <DashboardIcon iconClass='fs-5 me-2' />
                                Dashboard
                            </CustomNavlink>
                        </li>
                        <li className="nav-item">
                            <CustomNavlink to="/subscription" className="nav-link">
                                <SubscriptionIcon iconClass='fs-5 me-2' />
                                Subscription
                            </CustomNavlink>
                            {/* <a role='button' className="nav-link" onClick={() => {
                                handleActive('subscription');
                                setModalShow(true);
                            }
                            }>
                                <SubscriptionIcon iconClass='fs-5 me-2' />
                                Subscription
                            </a> */}
                        </li>
                        <li className={`nav-item ${(activeClass === 'profile') ? 'active' : null}`} onClick={() => handleActive('profile')}>
                            <a role='button' className="nav-link">
                                <ProfileIcon iconClass='fs-5 me-2' />
                                Profile
                            </a>
                        </li>
                        <li className='nav-item mobile'>
                            <a href="/dashboard" className="nav-link">
                                <NotificationIcon size={25} iconClass='fs-3 me-2' />
                            </a>
                        </li>
                        <li className='nav-item mobile'>
                            <a href="/dashboard" className="nav-link">
                                <SupportIcon size={25} iconClass='fs-3 me-2' />
                            </a>
                        </li>
                        <li className='nav-item mobile'>
                            <a role='button' className="nav-link" onClick={() => logout()}>
                                <MyAccount size={25} iconClass='fs-3 me-2' />
                                Logout
                            </a>
                        </li>
                    </ul>
                </div>
            </div>
            <SubscriptionModal
                show={modalShow}
                onHide={() => setModalShow(false)}
            />
        </React.Fragment>
    );
}

export default Sidebar;