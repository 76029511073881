import React from 'react';

const SearchIcon = ({
    iconClass,
    size = 20
}) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" stroke='currentColor' strokeWidth={1} width={size} height={size} viewBox="-2 -2 47.43 47.43" className={iconClass}><path className="cls-1" d="M17,4.83A12.16,12.16,0,1,0,29.14,17,12.16,12.16,0,0,0,17,4.83Zm0,23.31A11.16,11.16,0,1,1,28.14,17,11.17,11.17,0,0,1,17,28.14Z" /><path className="cls-1" d="M43.37,38.22,31.27,26.13a17,17,0,1,0-5.14,5.14L38.22,43.36a3.64,3.64,0,0,0,5.15-5.14ZM1,17A16,16,0,1,1,17,33,16,16,0,0,1,1,17ZM42.66,42.66a2.71,2.71,0,0,1-3.73,0l-12-12A16.92,16.92,0,0,0,30.69,27l12,12a2.64,2.64,0,0,1,0,3.73Z" /></svg>
    );
}

export default SearchIcon;