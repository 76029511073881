/**
 * @file Chart.jsx
 * @author Duncan Grubbs <duncan.grubbs@gmail.com>
 * @description Highcharts data plotting component
 */

import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Highcharts from 'highcharts/highstock';
import HighchartsReact from 'highcharts-react-official';
import Paper from '@material-ui/core/Paper';
import log from '../../util/logger';

require('highcharts/modules/exporting')(Highcharts);
require('highcharts/modules/export-data')(Highcharts);

const useStyles = makeStyles((theme) => ({
	containerCard: {
		padding: theme.spacing(2),
	},
}));

function Chart({
	data,
	ticker,
	scoreSelection,
	xSelection,
	handleClick,
	chartCallback
}) {
	const chartDiv = useRef();
	const [options, setOptions] = useState({});
	const [quarterIdMap, setQuarterIdMap] = useState({});
	const classes = useStyles();
	//getting prior score 

	//new function
	function findingPriorScore(x,y,data){
		let priorScoreKey = 0;
		data.findIndex((requiredValue, key) =>{
			if(requiredValue !== undefined && requiredValue.x === x){
				priorScoreKey = key === 0 ? 0 : key - 1;
			}else{
				return 0;
			}
		});
		return data[priorScoreKey].y;
	}
	function handlePointClick(event) {
		console.log(event.point.series.data);
		let priorScore = findingPriorScore(event.point.x,event.point.y,event.point.series.data);
		const date = new Date(event.point.category);
		const callId = quarterIdMap[`${event.point.series.name}_${date.getTime()}`];
		handleClick(callId);
		chartCallback(event.point.y, priorScore);
	}

	const baseChartOptions = {
		chart: {
			type: 'spline',
			height: 600,
		},
		title: {
			text: 'Innovation Score over Time',
		},
		xAxis: {
			type: 'datetime',
			title: {
				text: 'Date',
			},
		},
		tooltip: {
			shared: false,
			pointFormat: 'Score: {point.y:.2f}',
		},
		legend: {
			align: 'center',
			enabled: true,
		},
		exporting: {
			enabled: true,
		},
		plotOptions: {
			series: {
				marker: {
					enabled: true,
				},
				cursor: 'pointer',
				point: {
					events: {
						click: handlePointClick,
					},
				},
			},
		},
	};

	function buildChartOptions() {
		// construct our dataset that we will pass to high charts
		// it is an array of objects with a name (ticker) and some
		// data points
		let scoreKey = 'indexscore';

		Object.keys(scoreSelection).forEach((option) => {
			if (scoreSelection[option]) scoreKey = option;
		});

		// for each company
    
		const buildMap = {};
		const series = Object.keys(data).map((key) => {
			data[key].forEach((call) => {
				buildMap[`${key}_${new Date(call[xSelection]).getTime()}`] = call.id;
			});
			// for each earnings call for that company
			const dataSet = data[key]
				.map((item) => [new Date(item[xSelection]).getTime(), item[scoreKey]]);
			dataSet.sort((a, b) => (a[0] > b[0] ? 1 : -1));

			return {
				name: key,
				findNearestPointBy: 'xy',
				data: dataSet,
			};
		});
		setQuarterIdMap(buildMap);
		const yLabel = scoreSelection.sd ? 'Standard Deviations' : 'Score';

		// we store the chart options in the components state
		// and update that here
		setOptions({
			...baseChartOptions,
			series,
			subtitle: {
				text: `For ${ticker} tickers`,
			},
			yAxis: {
				opposite:false,
				title: {
					text: yLabel,
				},
			},
		});
	}

	useEffect(() => {
		if (data !== undefined && Object.keys(data).length > 0) {
			// we don't want to create a chart with empty data
			buildChartOptions();
		}
		// only update the chart when the data or ticker props change
	}, [data, ticker, scoreSelection, xSelection]);

	return (
		<Paper elevation={3} className={classes.containerCard}>
			<section>
				<div className="chart">
					<HighchartsReact
						highcharts={Highcharts}
						constructorType="stockChart"
						options={options}
						ref={chartDiv}
					/>
				</div>
			</section>
		</Paper>
	);
}

Chart.propTypes = {
	data: PropTypes.object.isRequired,
	handleClick: PropTypes.func.isRequired,
	ticker: PropTypes.string.isRequired,
	scoreSelection: PropTypes.object.isRequired,
	xSelection: PropTypes.string.isRequired,
};

export default Chart;
