import React from 'react';

function RealState({
    size = 20,
    color = '#fff',
    iconClass = '',
}) {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" fill={color} stroke='currentColor' strokeWidth={1} width={size} height={size} viewBox="0 0 44.43 44.43" className={iconClass}><g id="Layer_2" data-name="Layer 2"><g id="Layer_1-2" data-name="Layer 1"><path class="cls-1" d="M26.07,0H3.38A3.39,3.39,0,0,0,0,3.38V41a3.39,3.39,0,0,0,3.38,3.39H26.07A3.38,3.38,0,0,0,29.45,41V3.38A3.38,3.38,0,0,0,26.07,0ZM3.38,1H26.07a2.39,2.39,0,0,1,2.38,2.38V5.82H1V3.38A2.39,2.39,0,0,1,3.38,1ZM28.45,6.82V37.69H1V6.82ZM26.07,43.43H3.38A2.39,2.39,0,0,1,1,41V38.69H28.45V41A2.39,2.39,0,0,1,26.07,43.43Z" /><rect class="cls-1" x="11.48" y="40.52" width="6.49" height="1" /><path class="cls-1" d="M3.87,22.61a.51.51,0,0,0,.71,0L14.73,12.46l7.08,7.08v12.2h-3v-6a4.06,4.06,0,1,0-8.11,0v3.76a.5.5,0,0,0,1,0V25.77a3.06,3.06,0,1,1,6.11,0v6H7.65v-8.9a.5.5,0,0,0-.5-.5.5.5,0,0,0-.5.5v9.9H22.81V20.54l2.06,2.07a.5.5,0,0,0,.36.14.5.5,0,0,0,.35-.85l-2.77-2.77V12.45H18.62V15l-3.89-3.9L3.87,21.9A.51.51,0,0,0,3.87,22.61Zm15.75-9.16h2.19v4.68L19.62,16Z" /></g></g></svg >
    );
}

export default RealState;