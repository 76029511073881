/**
 * @file Sectors.jsx
 * @author @author Shehayar abbasi <sheharyar.abbasi@gmail.com>
 * @description Sectors information and filtering page.
 */

import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import * as Layout from 'react-bootstrap';
import * as Fontawesome from 'react-icons/fa';

import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import CircularProgress from '@material-ui/core/CircularProgress';
import Paper from '@material-ui/core/Paper';
import Api from '../../middleware/Api';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { createFilter, components } from 'react-select';
import Chip from '@mui/material/Chip';
import TextField from '@mui/material/TextField';
import DateRangePicker from '@mui/lab/DateRangePicker';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Button from '@mui/material/Button';
import log from '../../util/logger';
import ButtonGroup from '@mui/material/ButtonGroup';
import moment from 'moment';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import { Row, Col } from 'react-bootstrap';
import { CSVLink, CSVDownload } from 'react-csv';
import './style.css';
import arrow_icon from '../../images/arrow_icon.png';

function getStyles(name, personName, theme) {
	return {
		fontWeight:
			personName.indexOf(name) === -1
				? theme.typography.fontWeightRegular
				: theme.typography.fontWeightMedium,
	};
}

const useStyles = makeStyles((theme) => ({
	sector: {
		padding: theme.spacing(2),
		cursor: 'pointer',
	},
	title: {
		margin: theme.spacing(3, 0),
	},
}));
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
	PaperProps: {
		style: {
			maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
			width: 250,
		},
	},
};
const customStyles = {
	control: (provided, state) => ({
		...provided,
		backgroundColor: '#000000',
		boxShadow: 'none',
		borderColor: "#fff",
		"&:hover": {
			borderColor: "#fff",
		}
	}),
	singleValue: (provided, state) => ({
		...provided,
		color: '#ffffff',
	}),
	indicatorsContainer: (provided, state) => ({
		...provided,
		padding: 2,
	}),
	indicatorSeparator: () => ({
		width: 0,
	}),
	dropdownIndicator: (provided, state) => ({
		transition: 'color 150ms',
		boxSizing: 'border-box',
		display: 'flex',
		padding: 8,
		color: '#ffffff',
		textIndent: '-9999px',
		width: 35,
		backgroundColor: '#0065a4',
		backgroundPosition: '0 0',
		backgroundRepeat: 'no-repeat',
		backgroundSize: 'cover',
		backgroundImage: `url(${arrow_icon})`,
	}),
	multiValueLabel: (provided, state) => ({
		...provided,
		color: '#ffffff',
		backgroundColor: '#efad1d',
		borderRadius: 0,
	}),
	multiValueRemove: (provided, state) => ({
		...provided,
		color: '#ffffff',
		backgroundColor: '#efad1d',
		borderRadius: 0,
		"&:hover": {
			backgroundColor: "#efad1d",
			color: "#fff"
		}
	}),
	option: (provided, state) => ({
		...provided,
		backgroundColor: '#ffffff',
		color: '#000000',
	})
};
const DropdownIndicator = (props) => {
	return (<components.DropdownIndicator {...props}>.</components.DropdownIndicator>);
}

function SearchAndDisplay() {
	const [sectors, setSectors] = useState([]);
	const [loading, setLoading] = useState(true);
	const [value, setValue] = React.useState([null, null]);
	const classes = useStyles();
	const history = useHistory();
	const theme = useTheme();
	const [personName, setPersonName] = React.useState([]);
	const [searchResults, setSearchResults] = React.useState([]);
	const [excel, setExcel] = React.useState([]);
	const [number, setNumber] = React.useState(null);
	const [sectorOptions, setSectorOptions] = useState([]);
	const [isSector, setIsSector] = useState('');
	const [sector, setSector] = useState('None');
	const handleChange = (event) => {
		const {
			target: { value },
		} = event;
		setPersonName(
			// On autofill we get a the stringified value.
			typeof value === 'string' ? value.split(',') : value,
		);
	};

	function fetchData() {
		let sec = [];
		let sectorList = [];
		Api
			.GET('/fulltable')
			.then((response) => {
				response.map(r => {
					sec.push(r.sector);
					sectorList.push({
						label: r.sector,
						value: r.sector
					});
				});
				sectorList = [{ label: 'None', value: 'None' }, ...sectorList];
				setSectors(sec);
				setSectorOptions(sectorList);
				setLoading(false);
			});
	}

	function search(event) {
		event.preventDefault();
		setLoading(true);
		Api
			.POST('/search', {
				personName,
				value,
				number
			})
			.then((data) => {
				let copiedObj = JSON.parse(JSON.stringify(data)); //deep copy
				setSearchResults(data);

				let myArray = copiedObj.filter(function (obj) {
					delete obj.id;
					return obj;
				});
				setExcel(myArray);
				setLoading(false);
			})
			.catch((error) => {
				setLoading(false);
				log(error);
			});
	}

	useEffect(() => {
		fetchData();
	}, []);

	function exportPDF() {
		const unit = 'pt';
		const size = 'A4'; // Use A1, A2, A3 or A4
		const orientation = 'portrait'; // portrait or landscape

		const marginLeft = 40;
		const doc = new jsPDF(orientation, unit, size);

		doc.setFontSize(15);

		const title = 'Search Results';
		const headers = [['Date', 'Sector', 'Compnany', 'Index Score']];

		const data = searchResults.map(elt => [moment(elt.date).format('YYYY-MM-DD '), elt.sector, elt.company, Math.round(elt.indexscore)]);

		let content = {
			startY: 50,
			head: headers,
			body: data
		};

		doc.text(title, marginLeft, 40);
		doc.autoTable(content);
		doc.save('report.pdf');
	}

	const marStyle = { margin: '10px 10px 20px 0' };
	const tabStyle = { padding: '15px 10px' };

	const resetFunction = () => {
		setPersonName([]);
		setValue([null, null]);
		setNumber(null);
		setSearchResults([]);
		document.getElementById('filled-number').value = '';

	};
	const headersCSV = [
		{ label: 'Date', key: 'date' },
		{ label: 'Sector', key: 'sector' },
		{ label: 'Symbol', key: 'symbol' },
		{ label: 'Company', key: 'company' },
		{ label: 'Index Score', key: 'indexscore' },
	];
	const handleSectorChange = (data) => {
		const { value } = data;
		setPersonName(
			// On autofill we get a the stringified value.
			typeof value === 'string' ? value.split(',') : value,
		);
		setSector(value);
	}
	return (
		<React.Fragment>
			<div className="search-form search-white px-3 bg-black text-white mb-3 bordertop borderbottom">
				<Layout.Form.Group className='form-inline row'>
					<Layout.Col className='py-md-2 py-sm-1' sm={12} md={9} lg={9}>
						<Layout.Row>
							<Layout.Col sm={12} md={6} lg={6} className='inline py-sm-1'>
								<label className="label-col label-inline me-2">
									<img src={'assets/sectors/sector-search-icon.svg'} alt='icons' style={{ width: 40, height: 40 }} />
									Sectors
								</label>
								<Select
									components={{ DropdownIndicator }}
									id="sector"
									onChange={handleSectorChange}
									options={sectorOptions}
									value={isSector !== 'None' ? { label: sector, value: sector } : {
										label: 'None',
										value: 'None'
									}}
									styles={customStyles}
									className="form-control p-0 dropdown-green"
								/>
							</Layout.Col>
							<Layout.Col sm={12} md={6} lg={6} className='inline py-sm-1'>
								<LocalizationProvider dateAdapter={AdapterDateFns}>
									<DateRangePicker
										value={value}
										onChange={(newValue) => {
											setValue(newValue);
										}}
										renderInput={(startProps, endProps) => (
											<React.Fragment>
												<div className="datepicker-inline">
													<div className='inline'>
														<label className="label-col me-2">From</label>
														<input className='datepicker' ref={startProps.inputRef} {...startProps.inputProps} />
													</div>
													<div className='inline'>
														<label className="label-col me-2">To</label>
														<input className='datepicker' ref={startProps.inputRef} {...endProps.inputProps} />
													</div>
												</div>
											</React.Fragment>
										)}
									/>
								</LocalizationProvider>
							</Layout.Col>
						</Layout.Row>
						<Layout.Col sm={12} md={12} lg={12} className='inline mt-md-2 mt-sm-1'>
							<div className='inline justify-align-content-start'>
								<label className="label-col fw-normal fs-6 margin-left">Number of Companies to Rank</label>
								<Layout.Form.Control
									id="filled-number"
									type="number"
									label="Number Of Records"
									inputlabelprops={{
										shrink: number !== null ? true : false,
									}}
									onChange={(e) => setNumber(e.target.value)}
									className="input-number"
								/>
							</div>
						</Layout.Col>
					</Layout.Col>
					<Layout.Col sm={12} md={3} lg={3} className='btn-mobile py-md-2 py-sm-1'>
						<Layout.Button variant='primary' className='me-3 btn-blue' onClick={(e) => search(e)} style={{ fontWeight: 500 }}>SEARCH</Layout.Button>
						<Layout.Button variant='success' className='me-3 btn-green' onClick={() => resetFunction()} style={{ fontWeight: 500 }}>RESET</Layout.Button>
					</Layout.Col>
				</Layout.Form.Group>
			</div>
			<Layout.Container className='vh-100'>
				{loading ? <div style={{ height: '100px' }}><CircularProgress className='text-white me-2' /></div> : (
					<>
						{
							(searchResults.length > 0) ? (
								<>
									<Layout.Row className='text-white d-flex align-items-center'>
										<Layout.Col sm={12} md={9}>
											<div className="company-search-box">
												<p className='text-desktop mb-0 me-3'>Make a selection from the sector(s) of your choice to see the <br />highest ranking of a company's Innovation Intensity Score.</p>
												<p className='text-mobile mb-0 me-3'>Make a selection from the sector(s) of your choice to see the highest ranking of a company's Innovation Intensity Score.</p>
												<div className='company-search mx-auto'>
													<Layout.FormControl
														id="search-company-name"
														type="text"
														placeholder='Type a company name here...'
														style={{
															color: '#ffffff',
															backgroundColor: 'transparent',
															borderColor: '#ffffff'
														}}
													/>
													<Fontawesome.FaSearch className='icons' />
												</div>
											</div>
										</Layout.Col>
										<Layout.Col sm={12} md={3}>
											<p className='mb-0 fs-6 mb-2'>Export Innovation Scores</p>
											<Layout.Button variant='dark export' onClick={() => exportPDF()} className='me-2'>PDF</Layout.Button>
											<Layout.Button variant='dark export'><CSVLink style={{ color: 'white' }} data={excel} headers={headersCSV}>Excel / CSV</CSVLink></Layout.Button>
										</Layout.Col>
									</Layout.Row>
									<Layout.Card className='card-blue my-3'>
										<Layout.Card.Body className='p-sm-0'>
											<Layout.Table responsive className='rank-inn-result'>
												<thead>
													<tr>
														<th>Sector</th>
														<th>Symbol</th>
														<th>Company Name</th>
														<th>Score</th>
														<th>Date</th>
													</tr>
												</thead>
												<tbody>
													{searchResults.map(result => (
														<tr>
															<td>{result.sector}</td>
															<td>{result.symbol}</td>
															<td>{result.company}</td>
															<td>{(result.indexscore).toFixed(0)}</td>
															<td>{moment(result.date).format('YYYY-MM-DD ')}</td>
														</tr>
													))}
												</tbody>
											</Layout.Table>
										</Layout.Card.Body>
									</Layout.Card>
								</>
							) : (
								<div className="company-search-box">
									<p className='text-desktop mb-0 me-3'>Make a selection from the sector(s) of your choice to see the <br />highest ranking of a company's Innovation Intensity Score.</p>
									<p className='text-mobile mb-0 me-3'>Make a selection from the sector(s) of your choice to see the highest ranking of a company's Innovation Intensity Score.</p>
								</div>
							)}
					</>
				)}
			</Layout.Container>
		</React.Fragment>
	);
}

export default SearchAndDisplay;
