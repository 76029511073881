import React from 'react';

const RankInnovation = ({
    iconClass,
    size = 20
}) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" stroke='currentColor' strokeWidth={1} width={size} height={size} viewBox="0 0 42.37 43.93" className={iconClass}><g id="Layer_2" data-name="Layer 2"><g id="Layer_1-2" data-name="Layer 1"><rect class="cls-1" x="20.68" width="1" height="3.18" /><rect class="cls-1" y="20.68" width="3.18" height="1" /><rect class="cls-1" x="39.19" y="20.68" width="3.18" height="1" /><rect class="cls-1" x="5.74" y="34.54" width="3.18" height="1" transform="translate(-22.63 15.44) rotate(-45)" /><rect class="cls-1" x="33.45" y="6.83" width="3.18" height="1" transform="translate(5.08 26.92) rotate(-45)" /><rect class="cls-1" x="6.83" y="5.74" width="1" height="3.18" transform="translate(-3.04 7.33) rotate(-45)" /><rect class="cls-1" x="34.54" y="33.45" width="1" height="3.18" transform="translate(-14.51 35.04) rotate(-45)" /><path class="cls-1" d="M33.07,14.83l2.62-2.63-5.5-5.51L27.56,9.32a14.23,14.23,0,0,0-2.44-1V4.59H21.18A14.8,14.8,0,0,0,10.39,9.4,13.13,13.13,0,0,0,7,19.62c.55,5.49,3.45,8.16,6,10.51,2.25,2.06,4.19,3.84,4.19,7.15V40a3.94,3.94,0,0,0,7.88,0V34.05a14.28,14.28,0,0,0,2.44-1l2.63,2.63,5.5-5.51-2.62-2.63a13.16,13.16,0,0,0,1-2.43h3.72V17.27H34.07A13.23,13.23,0,0,0,33.07,14.83Zm-11.39.6a5.78,5.78,0,0,1,0,11.51Zm-7.95,14c-2.56-2.34-5.2-4.76-5.7-9.87a12.14,12.14,0,0,1,3.1-9.45A13.82,13.82,0,0,1,20.68,5.6V36.78H18.23C18,33.35,15.94,31.42,13.73,29.39ZM24.12,40a2.94,2.94,0,0,1-5.88,0V37.78h5.88ZM36.79,24.1H33.3l-.09.38a12.16,12.16,0,0,1-1.2,2.9l-.18.33,2.45,2.45-4.09,4.1-2.46-2.45L27.4,32a13.54,13.54,0,0,1-2.91,1.2l-.37.1v3.49H21.68V27.94a6.77,6.77,0,0,0,0-13.51V5.59h2.44V9.08l.37.1a13,13,0,0,1,2.91,1.2l.33.18,2.46-2.45,4.09,4.09-2.45,2.46L32,15a12.16,12.16,0,0,1,1.2,2.9l.09.38h3.49V24.1Z" /></g></g></svg>
    );
}

export default RankInnovation;