/**
 * @file Admin.jsx
 * @author Duncan Grubbs <duncan.grubbs@gmail.com>
 * @description Admin route for managing users, etc.
 */

import React, { useEffect, useState } from 'react';

import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Chip from '@material-ui/core/Chip';
import Container from '@material-ui/core/Container';
import Delete from '@material-ui/icons/Delete';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Paper from '@material-ui/core/Paper';
import Switch from '@material-ui/core/Switch';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TextField from '@material-ui/core/TextField';
import LinearProgress from '@material-ui/core/LinearProgress';

import log from '../../util/logger';
import Api from '../../middleware/Api';

const useStyles = makeStyles((theme) => ({
	container: {
		marginTop: theme.spacing(2),
	},
	editForm: {
		display: 'flex',
		flexDirection: 'row',
		alignItems: 'center',
		margin: theme.spacing(2, 0),
	},
	editFormInput: {
		margin: theme.spacing(0, 2),
	},
	editFormButton: {
		maxHeight: '30px',
		margin: theme.spacing(0, 1),
	},
	addButton: {
		margin: theme.spacing(2, 0),
	},
}));

function Admin() {
	const [users, setUsers] = useState([]);
	const [userToEdit, setUserToEdit] = useState(null);
	const [addingUser, setAddingUser] = useState(false);
	const [loading, setLoading] = useState(false);
	const classes = useStyles();

	const generatePin = () => {
		const pin = Math.floor(Math.random() * 9999);
		return String(pin).padStart(4, '0');
	};

	const [userToAdd, setUserToAdd] = useState({
		email: 'email',
		pin: generatePin(),
		isadmin: false,
	});

	function getUsers() {
		setLoading(true);
		Api
			.GET('/users')
			.then((data) => {
				setLoading(false);
				setUsers(data);
			})
			.catch((error) => {
				setLoading(false);
				log(error);
			});
	}

	function editRow(event) {
		const email = event.currentTarget.id;
		const filtered = users.filter((user) => user.email !== email);
		const user = users.filter((user) => user.email === email);
		setUserToEdit(user[0]);
		setUsers(filtered);
	}

	function handleChange(event) {
		if (event.target.name === 'isadmin') {
			setUserToEdit({
				...userToEdit,
				isadmin: !userToEdit.isadmin,
			});
		} else {
			setUserToEdit({
				...userToEdit,
				[event.currentTarget.id]: event.currentTarget.value,
			});
		}
	}

	function handleAddUserChange(event) {
		if (event.target.name === 'isadmin') {
			setUserToAdd({
				...userToAdd,
				isadmin: !userToAdd.isadmin,
			});
		} else {
			log(event.currentTarget.id, event.currentTarget.value);
			setUserToAdd({
				...userToAdd,
				[event.currentTarget.id]: event.currentTarget.value,
			});
		}
	}

	function cancelEdit() {
		getUsers();
		setUserToEdit(null);
	}

	function saveEdit() {
		setUserToEdit(null);
		setLoading(true);
		Api
			.PUT('/users', { user: userToEdit })
			.then((data) => {
				setLoading(false);
				setUsers(data);
			})
			.catch((error) => log(error));
	}

	function deleteRow(event) {
		setLoading(true);
		Api
			.DELETE('/users', { email: event.currentTarget.id })
			.then((data) => {
				setLoading(false);
				setUsers(data);
			})
			.catch((error) => log(error));
	}

	function setRandomPin() {
		const pin = generatePin();
		if (addingUser) {
			setUserToAdd({
				...userToAdd,
				pin,
			});
		}

		if (userToEdit !== null) {
			setUserToEdit({
				...userToEdit,
				pin,
			});
		}
	}

	function addUserClick() {
		setAddingUser(true);
	}

	function saveUser() {
		setLoading(true);
		Api
			.POST('/users', { user: userToAdd })
			.then((data) => {
				setLoading(false);
				setAddingUser(false);
				setUsers(data);
			})
			.catch((error) => log(error));
	}

	const cancelSave = () => setAddingUser(false);

	useEffect(() => {
		getUsers();
	}, []);

	return (
		<Container maxWidth="lg" className={classes.container}>
			<h2>Admin Dashboard</h2>
			<Button className={classes.addButton} size="small" color="primary" onClick={addUserClick} variant="outlined">Add User</Button>
			{
				addingUser && (
					<FormControl className={classes.editForm}>
						<TextField
							className={classes.editFormInput}
							value={userToAdd.email}
							label="Email"
							type="text"
							id="email"
							name="email"
							onChange={handleAddUserChange}
						/>
						<TextField
							className={classes.editFormInput}
							value={userToAdd.pin}
							label="Pin"
							type="text"
							id="pin"
							name="pin"
							onChange={handleAddUserChange}
						/>
						<Button className={classes.editFormButton} size="small" color="primary" variant="outlined" onClick={setRandomPin}>Random Pin</Button>
						<FormControlLabel
							control={<Switch color="secondary" size="large" name="isadmin" checked={userToAdd.isadmin} onChange={handleAddUserChange} />}
							label="Is Admin"
						/>
						<Button className={classes.editFormButton} size="small" color="primary" variant="outlined" onClick={cancelSave}>Cancel</Button>
						<Button className={classes.editFormButton} size="small" color="secondary" variant="contained" onClick={saveUser}>Save</Button>
					</FormControl>
				)
			}

			{
				userToEdit && (
					<FormControl className={classes.editForm}>
						<TextField
							className={classes.editFormInput}
							value={userToEdit.email}
							label="Email"
							type="text"
							id="email"
							name="email"
							onChange={handleChange}
						/>
						<TextField
							className={classes.editFormInput}
							value={userToEdit.pin}
							label="Pin"
							type="text"
							id="pin"
							name="pin"
							onChange={handleChange}
						/>
						<Button className={classes.editFormButton} size="small" color="primary" variant="outlined" onClick={setRandomPin}>Random Pin</Button>
						<FormControlLabel
							control={<Switch color="secondary" size="large" name="isadmin" checked={userToEdit.isadmin} onChange={handleChange} />}
							label="Is Admin"
						/>
						<Button className={classes.editFormButton} size="small" color="primary" variant="outlined" onClick={cancelEdit}>Cancel</Button>
						<Button className={classes.editFormButton} size="small" color="secondary" variant="contained" onClick={saveEdit}>Update</Button>
					</FormControl>
				)
			}
			{ loading && <LinearProgress /> }
			<TableContainer component={Paper}>
				<Table aria-label="simple table">
					<TableHead>
						<TableRow>
							<TableCell>Email</TableCell>
							<TableCell>Pin</TableCell>
							<TableCell>Is Admin?</TableCell>
							<TableCell>Edit</TableCell>
							<TableCell>Delete</TableCell>
						</TableRow>
					</TableHead>
					<TableBody>
						{users.map((user) => (
							<TableRow>
								<TableCell>{user.email}</TableCell>
								<TableCell>{user.pin}</TableCell>
								<TableCell>
									{ user.isadmin ? (
										<Chip
											size="small"
											color="secondary"
											label="Yes"
										/>
									) : (
										<Chip
											size="small"
											color="primary"
											label="No"
										/>
									)}
								</TableCell>
								<TableCell>
									<Button size="small" variant="outlined" id={user.email} onClick={editRow}>Edit</Button>
								</TableCell>
								<TableCell>
									<Button size="small" id={user.email} onClick={deleteRow}><Delete /></Button>
								</TableCell>
							</TableRow>
						))}
					</TableBody>
				</Table>
			</TableContainer>
		</Container>
	);
}

export default Admin;
